import "./App.css";
import logo from "../src/assets/logo.png";
import logo2 from "../src/assets/site-under-construction.svg";
import img1 from "../src/assets/1d.png";
import img3 from "../src/assets/3.png";
import img4 from "../src/assets/5.png";

function App() {
  return (
    <div className="coming-soon">
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
      </header>
      <main className="main">
        <img src={logo2} alt="Coming Soon" className="coming-soon-image" />
        <p className="coming-soon-text">قريباً ... تحت الانشاء</p>
        <div className="scroll-container">
          <img
            style={{ marginInline: "5px" }}
            src={img1}
            alt="Coming Soon"
            className="coming-soon-imageNew"
          />
          <img
            style={{ marginInline: "5px" }}
            src={img3}
            alt="Coming Soon"
            className="coming-soon-imageNew"
          />
          <img
            style={{ marginInline: "5px" }}
            src={img4}
            alt="Coming Soon"
            className="coming-soon-imageNew"
          />
        </div>
      </main>
      <footer className="footer">
       <p >&copy; 2024 نادي المهندس. جميع الحقوق محفوظة.</p> 
      </footer>
    </div>
  );
}

export default App;
